.SifreDegisim {
  margin-top: 3.5em;
  text-align: left;
  height: 30px;
  line-height: 30px;
  text-indent: 10px;
}
.HeaderBar {
  justify-content: center;
  padding: 0.75em;
}

.BackgroundStyle{
  background: '#fbbced'
}

