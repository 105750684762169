/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 32px;
  height: 25px;
  left: 16px;
  top: 12px;
}
 
/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #be2828;
}
 
/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}
 
/* Color/shape of close button cross */
.bm-cross {
  background: #be2828;
}
 
/* General sidebar styles */
.bm-menu {
  background: #ffffff;
  padding: 1em 0;
  font-size: 1.1em;
}
 
/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}
 
/* Wrapper for item list */
.menu-item {
  color: #000000;
  padding: 0.8em;
  font-size: 95%;
}
 
/* Individual item */
.bm-item {
  display: inline-block;
}
 
/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}