.altGrid{
  /* margin-left: 500px; */
  text-align: middle;
  height: 30px;
  line-height: 30px;
  /* width:1000px; */
  text-indent: 1px;
 }

.carouselCss {
  /* margin: 100px; */
  text-align: middle;
  height: 30px;
  line-height: 30px;
  /* width:1000px; */
  text-indent: 1px;
 }

.GridCss {
  /* margin: 100px; */
  text-align: middle;
  height: 30px;
  line-height: 30px;
  /* width:1000px; */
  text-indent: 1px;
 }

.YoneticiAnaSayfa {
  /* margin-left: 300px; */
  margin-top: 3.5em;
  text-align: middle;
  height: 30px;
  line-height: 30px;
  /* width:1000px; */
  text-indent: 1px;
}

.HeaderBar {
  justify-content: center;
  padding: 0.75em;
}