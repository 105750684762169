/* .Login{
    align-content:center;
    margin-left: 250px;
    text-align: left;
    background: pink;
    /* height: 30px;
    line-height: 30px;
    width:600px;
    text-indent: 10px;*/
/* } */ 

.DivStyle  {
  color: 'red'
  }

.BackgroundStyle{
  background: 'pink'
}